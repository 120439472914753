<template>
  <div>
    <not-found
      :title="$t('common.not_found_title')"
      :sub-title="$t('common.not_found_subtitle')"
      :button-title="$t('common.back_to_main')"
      :route-name="backToRoute"
      :icon="'not-found-icon'"
    />
  </div>
</template>
<script>
import NotFound from '@/components/NotFound.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    NotFound,
  },
  data() {
    return {
      backToRoute: '',
    };
  },
  computed: {
    ...mapGetters({
      getProfileInfo: 'getProfileInfo',
    }),
  },
  watch: {
    getProfileInfo: {
      handler(newVal, oldVal) {
        if (newVal.permissions) {
          this.backToRoute = newVal.permissions[0].split('view_')[1];
        }
      },
      deep: true,
    },
  },
  mounted() {},
  created() {
    this.loadProfileInfo();
  },
  methods: {
    ...mapActions({
      loadProfileInfo: 'loadProfileInfo',
    }),
  },
};
</script>
