<template>
  <div class="not-found">
    <logo-dark class="not-found-logo" />
    <logo-larg class="not-found-logo-larg-1" />
    <logo-larg class="not-found-logo-larg-2" />
    <logo-larg class="not-found-logo-larg-3" />
    <div class="not-found-content">
      <component :is="icon" class="not-found-content-avatar" />
      <h1 class="text-bold-48 text-white">
        {{ title }}
      </h1>
      <p class="text-semi-30 text-white">
        {{ subTitle }}
      </p>
      <wameed-btn
        classes="text-bold-14 text-main rounded-10 border-white mx-auto"
        :title="buttonTitle"
        type="button"
        variant="white"
        @submitAction="backToMain"
      />
    </div>
  </div>
</template>

<script>
import WameedBtn from '@/components/WameedBtn.vue';

export default {
  components: {
    WameedBtn,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    buttonTitle: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    backToMain() {
      this.$router.push({ name: this.routeName, lang: 'ar' });
    },
  },
};
</script>
